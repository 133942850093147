import React from 'react';
import './App.css';
import {withStyles} from "@material-ui/core/styles";
import styles from "./styles";
import Photos from "./Photos";

const projectData = require('./projects.json');

class Estate extends React.Component {

    render() {
        const blocks = [];
        for (let section in projectData) {
            const obj = projectData[section];
            blocks.push(<div className="cRow" key={section}>
                <div className="cTitle">{obj.heading}</div>
                <div className={`cItem cBg`} style={{backgroundColor: obj.color}}>
                    <div className="cInfo">
                        <div className="cContent" >
                            <div id={section} className="gallery">
                                {(obj.video) &&
                                    <video className="kpVideo" controls preload="auto" poster={obj.video.poster}>
                                        <source src={obj.video.src} type="video/mp4"/>
                                    </video>}
                                <Photos {...obj} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>);
        }
        return <div className="App">{blocks}</div>

    }
}

export default withStyles(styles)(Estate);
