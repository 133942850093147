import React from 'react';
import './App.css';
import styles from "./styles";
import {withStyles} from "@material-ui/core/styles";
import {Grid, Typography} from "@mui/material";
import Photos from "./Photos";

class Caregiving extends React.Component {
    render() {
        return <div className="App" id={"caregiving"}>
            <div style={{padding: '10%'}}>
                <h2 style={{textAlign: 'center'}}>Caregiving for Uncle Gene</h2>

                <Grid className={"imgList large"} container justifyContent={'center'} alignContent={'center'}
                      alignItems={'center'}>
                    <Grid item><img src={'/photos/caregiving/dad.jpg'}/></Grid>
                    <Grid item><img src={'/photos/caregiving/dad-nile-mower.jpg'}/></Grid>

                </Grid>

                <h4 style={{marginBottom: 0}}>Offering</h4>
                <Typography variant={'body1'}>$2,200 for your time and labor.</Typography>
                <Typography variant={'body1'}>$500 and a Costco membership for groceries.</Typography>
                <Typography variant={'body1'}>Work-trade option for car and/or housing.</Typography>


                <h4 style={{marginBottom: 0}}>Asking</h4>
                <Typography variant={'body1'}>2 hours daily for breakfast, dinner and some surrounding
                    assistance.</Typography>

                <hr/>

                <Typography variant={'body1'}>Below is a very long list of chores for him and you. He still <em>can</em> do 70% of his daily routine. However, most days he
                    just won't. While he remains joyful most days, he struggles with pain, fatigue, memory or confusion.
                    The goal is to find a healthy balance of encouraging, helping, and just doing some things for
                    him. Overall, as long as food is prepped and available in his mini fridge, you do not need to be present for every meal or activity.</Typography>

                <dl style={{marginTop: 50}}>
                    <dt>Daily Routine</dt>
                    <dd>Take morning medication</dd>
                    <dd>Fix a hot breakfast</dd>
                    <dd>Brush teeth</dd>
                    <dd>Shower</dd>
                    <dd>Get dressed</dd>
                    <dd>Do exercise / stretching / breathwork videos</dd>
                    <dd>Family phone calls</dd>
                    <dd>Lunch (can be simple as cereal)</dd>
                    <dd>Afternoon Medication</dd>
                    <dd>Hot Dinner</dd>
                    <dd>Bedtime: lights / brushing teeth / charging phone</dd>

                    <dt style={{marginTop: 10}}>Once Weekly Cleaning</dt>
                    <dd>Wipe down sanitize walker and grab bars</dd>
                    <dd>Clear out dishes and put away</dd>
                    <dd>Change bedding</dd>
                    <dd>Wash laundry</dd>
                    <dd>Put away laundry</dd>
                    <dd>Vacuum</dd>
                    <dd>Empty trashes (sunday night)</dd>
                    <dd>Clean off desks</dd>

                    <dt style={{marginTop: 10}}>Monthly cleaning</dt>
                    <dd>Grocery run</dd>
                    <dd>Clean toilet</dd>
                    <dd>Clean bathroom sink / floors / mirror</dd>
                    <dd>Clean shower</dd>
                    <dd>Organize closet</dd>
                    <dd>Clean kitchenette</dd>
                    <dd>Clean Microwave / Toasters / skillet</dd>
                    <dd>Clean refrigerator</dd>
                    <dd>Dusk shelves</dd>
                    <dd>Reorganize office / desk</dd>
                    <dd>Clean sliding door tracks</dd>
                </dl>


                <Typography variant={"body1"} style={{textAlign: 'center', marginTop: 40}}>Keep in mind this role is for
                    the primary caregiver responsible for daily duties. However, he is already blessed with 5 awesome
                    housemates across the 6 acres here. If you need a morning, afternoon, or day off, the right attitude
                    and maybe a bribe is all you'll need.</Typography>
                <Grid className={"imgList"} container justifyContent={'center'} alignContent={'center'}
                      alignItems={'center'}>
                    <Grid item><img src={'/photos/caregiving/dad-n-crew.jpg'}/></Grid>
                    <Grid item><img src={'/photos/caregiving/dad-michael-melanie.jpg'}/></Grid>
                </Grid>


                <hr/>

                <Typography variant={"body1"} style={{textAlign: 'center', marginTop: 40}}>If Housing is needed, we can
                    offer this 400<sup>sqft.</sup> unit for $1,600 as is and $2,500 once all <a
                        href={"https://docs.google.com/spreadsheets/d/1YVKUhjTzBc-vxYIf4zipiraKXiOk1zlvhOICool52Jo/edit?usp=sharing#gid=1879886827"}
                        target={"_blank"}>planned improvements</a> are made. Click photos to expand.</Typography>

                    <Photos
                        photos={[
                            {"src": "/photos/caregiving/livingroom.jpg"},
                            {"src": "/photos/caregiving/kitchen.jpg"},
                            {"src": "/photos/caregiving/room1.jpg"},
                            {"src": "/photos/caregiving/backyard.jpg"},
                            {"src": "/photos/caregiving/backyard2.jpg"},
                            {"src": "/photos/caregiving/backyard-grill.jpg"},
                        ]}
                    />

                <Typography variant={"body1"} style={{textAlign: 'center', marginTop: 40}}>If a car is needed, we can
                    offer one of these Honda Elements for $400 - $800 / month depending on usage</Typography>
                <Grid className={"imgList"} container justifyContent={'center'} alignContent={'center'}
                      alignItems={'center'}>
                    <Grid item><img src={'/photos/caregiving/element04.jpg'}/></Grid>
                    <Grid item><img src={'/photos/caregiving/element07.jpg'}/></Grid>
                </Grid>

            </div>


        </div>
    }
}

export default withStyles(styles)(Caregiving);
