import React from 'react';
import {Grid} from '@mui/material';

const Landing = (p) => {
    // const theme = useTheme();
    // const fabRotation = theme.palette.mode === 'dark' ? 180 : 0;

    return (
        <Grid container justifyContent={'center'} alignContent={'center'} alignItems={'center'} style={{textAlign:'center', marginTop:80}}>
            <Grid item xs={12}><img src={'/assets/landing-white.png'} /></Grid>
        </Grid>
    );
};

export default Landing
