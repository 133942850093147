export default (theme) => ({
    logoParts: {
        textAlign:'center',
        '& .text' : {
            position:'relative',
            zIndex:1,
            width:'100%'
        }
    },
    logoMain: {
        textAlign:'center',
        width:'100%'
    },
    dialogHead: {
        backgroundColor: '#202020',
        padding: '4px 8px',
        color: theme.palette.primary.light
    },
    appBar: {
        backgroundColor: '#202020',
        height: 45,
        width: '100%',
        marginBottom: 20
    },
    appForm: {
        color: '#202020',
        fontWeight: 600,
        borderTop: '1px solid #202020'
    },
    appSlider: {
        textAlign: 'right',
        margin: '55px 0 25px 0'
    },
    sliderLabel: {
        marginBottom: 0
    },
    subheader: {
        fontSize: 25,
        marginBottom: 0,
        fontWeight: 800,
        borderBottom: '1px solid #202020'
    },
    placeholder: {
        textAlign: 'left',
        color: '#202020',
        fontWeight: 600
    },
    disabled: {
        color: 'rgba(0, 0, 0, 0.38)',
        fontWeight: 500
    },
    dtImg: {
        height: 70,
        backgroundSize: 'cover',
        backgroundPosition: 'center center'
    },
    investment: {
        color: 'green'
    },
    warning: {
        color: 'red'
    },
    gridList: {
        flexWrap: 'nowrap',
        transform: 'translateZ(0)',
        '& .MuiGridList-root': {
            transform: 'translateZ(0)',
            '*::-webkit-scrollbar': {
                width: '0.4em'
            },
            '*::-webkit-scrollbar-track': {
                '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
            },
            '*::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(255,255,255,.1)',
                outline: '1px solid #fff'
            }
        }

    },
    gridListTitle: {
        color: theme.palette.primary.light,
        fontSize: 13,
        fontWeight: 800,
        textShadow: '-1px 2px 2px rgba(32, 32, 32, .25)'
    },
    gridListTitleBar: {
        background:
            'linear-gradient(to top, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0) 100%)',
    },
    accordion : {

    },
    accordionHead : {
        background:
            'linear-gradient(330deg, rgba(144,171,67,.4) 0%,  rgba(255,255,255,0)  50%, rgba(144,171,67,.4) 100%)',
    },
    imgFluid: {
        width: '100%'
    },
    ul: {
        listStyle: "disc",
        '& li': {
            marginBottom:6
        }
    },
    ulNum: {
        listStyle: "decimal",
        '& li': {
            marginBottom:6
        }
    }
})
