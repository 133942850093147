import React, {useState} from 'react';

import './App.css';
import {BrowserRouter, Link, Route, Routes} from "react-router-dom";
import Estate from "./Estate";
import Landing from './Landing';
// past > old site
// present > tiny home, sun sprouts, pottery
// future > ruhralfarms?
import {createTheme, CssBaseline, Fab, ThemeProvider} from '@mui/material';
import Caregiving from "./Caregiving";

const App = (props) => {
    const [darkMode, setDarkMode] = useState(false);

    const toggleDarkMode = () => {
        setDarkMode(!darkMode);
    };

    const theme = createTheme({
        palette: {
            primary: {
                main: '#943E0F'
            },
            secondary: {
                main: '#f57c00'
            },
            mode: darkMode ? 'dark' : 'light',
        },
    });

    return (
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <div style={{minHeight: '100vh', display: 'flex', flexDirection: 'column'}}>
                    <div style={{flex: 1}}>
                        <Routes>
                            <Route index path="/past" element={<Estate/>}/>
                            <Route index path="/future" element={<Estate/>}/>
                            <Route index path="/caregiving" element={<Caregiving/>}/>
                            <Route index path="/" element={<Landing/>}/>
                        </Routes>
                    </div>
                    {document.location.pathname !== '/past' &&
                        <Fab component={Link} to={'/past'} size={"large"} style={{bottom: 30, left: 30}}>
                            <img src={'/photos/gardens/10451797_10152827970320180_4591079298001359005_n.jpg'}
                                 style={{width: 50, borderRadius: 50}}/>
                        </Fab>
                    }
                </div>
            </ThemeProvider>
        </BrowserRouter>
    );
};

export default App;
